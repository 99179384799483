<template>
  <v-container fluid class="pa-0">
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <v-row>
        <v-col flex>
          <div class="back-to-projects" @click="handleBackClick">
            <v-icon color="blue">mdi-chevron-left</v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('testruns.create_testrun.back_to_testrun') }}
            </p>
          </div>
        </v-col>
      </v-row>
      <template>
        <v-row justify="center">
          <v-col md="5" lg="4">
            <h2 class="text-start">{{ $t('testruns.create_testrun.title') }}</h2>
          </v-col>
        </v-row>
        <v-form role="createRunForm" ref="runForm">
          <v-row justify="center">
            <v-col md="5" lg="4">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
                {{ $t('testruns.create_testrun.testrun_name') }}<strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field
                v-model="selectedRun.name"
                type="text"
                dense
                single-line
                filled
                placeholder="Test Run Name"
              
                :rules="requiredRule"
                class="rounded-lg"
                clear-icon="body-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="5" lg="4">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">{{ $t('testruns.create_testrun.description') }}</p>
              <v-text-field
                v-model="selectedRun.description"
                type="text"
                dense
                single-line
                filled
                placeholder="Description"
                hide-details
                class="rounded-lg"
                clear-icon="body-2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="5" lg="4">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">{{ $t('testruns.create_testrun.milestone') }}</p>
              <v-combobox
                v-model="selectedRun.milestone"
                type="text"
                dense
                single-line
                filled
                placeholder="Choose milestone"
                hide-details
                :items="milestones"
                class="rounded-lg"
                clear-icon="body-2"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="5" lg="4">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">{{ $t('testruns.create_testrun.priority') }}</p>
              <v-combobox
                v-model="selectedRun.priority"
                type="text"
                dense
                single-line
                filled
                placeholder="Choose Priority"
                hide-details
                :items="priorities"
                class="rounded-lg"
                clear-icon="body-2"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="5" lg="4">
              <p class="d-flex ma-0 mb-2 font-weight-medium body-2">{{ $t('testruns.create_testrun.tags') }}</p>
              <v-combobox
                v-model="selectedRun.tags"
                type="text"
                dense
                single-line
                filled
                :placeholder="$t('choose_tags')"
                hide-details
                :items="tags"
                class="rounded-lg"
                clear-icon="body-2"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <CaseManagement 
      v-if="showSelectCases"
      :quick-create="false" 
      :show-collapse="false"
      @folder-select="getCases"
      @selectedCases="handleCases"
      :cases="cases"
      ></CaseManagement>
      <v-row justify="end" class="mt-4">
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-menu
              top
              offset-y
              :nudge-top="4"
              v-model="menuOpen"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  dark
                  large
                  color="gray-100"
                  class="text-capitalize font-weight-bold black--text mr-4 mt-2"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                >
                  {{ $t('testruns.create_testrun.actions') }}<v-icon>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>
              <v-list dense class="text-left">
                <v-list-item @click="handleDuplicateClick">
                  <v-list-item-title>{{ $t('testruns.create_testrun.duplicate_testrun') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toggleShowCases">
                  <v-list-item-title>{{ showSelectCases ? $t('testruns.create_testrun.remove_testcase')  : $t('testruns.create_testrun.add_testcase') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
              @click="createTestRuns"
            >
              {{ $t('testruns.create_testrun.title') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <ProjectDiscardDialog
      :title="$t('testruns.create_testrun.close_dialog.title')"
      v-model="showConfirmBackDialog"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-container>
</template>

<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import CaseManagement from '@/components/Cases/CaseManagement'
import { mapState, createNamespacedHelpers } from 'vuex';
import makeCasesService from '@/services/api/case'
const { mapActions, mapMutations } = createNamespacedHelpers('run');

export default {
  name: 'TestRunCreate',
  components: {
    ProjectDiscardDialog,
    CaseManagement
  },
  data() {
    return {
      requiredRule: [v => !!v || this.$t('thisFieldIsRequired')],
      selectedRun: {},
      menuOpen: false,
      showConfirmBackDialog: false,
      search: '',
      selectedCases: [],
      showSelectCases: false,
      milestones: ["Release1.1", "Release2.2", "Release3.3"],
      priorities: ["High", "Medium", "Low"],
      tags: ["#smoke","#regression","#feature","#security","#integration"],
      cases:[]
    };
  },
  computed: {
    ...mapState('user',['currentAccount']),
  },
  methods: {
    ...mapActions(['createTestRun']),
    ...mapMutations(['UPDATE_SELECTED']),
    async getCases(folderUID){
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      await caseService.getFolderCases(handle, projectKey, folderUID).then(response => {
          this.cases = response.data.cases;

      }).catch(err => {
        console.log(err)
      })
    },
    createTestRuns() {      // TODO - consolidate create and update between this and RunAddCaseView.vue
      if(this.$refs.runForm.validate()){
        const payload = {
          name: this.selectedRun.name,
          customFields: {
            assign: this.currentAccount.handle,
            description: this.selectedRun.description,
            milestone: this.selectedRun.milestone,
            priority: this.selectedRun.priority,
            status: 'Active',
            tags: this.selectedRun.tags,
            progress: 0,
            star: true,
            case_count: 0,
            archived: false,
          },
          externalId: 'testfiesta',
          source: 'testfiesta-web',
          projectKey: this.$route.params.key,
        };

        if(this.selectedCases.length){
          const selectedCases = this.selectedCases.map(item => item.testCaseRef)
          payload.customFields.case_count = selectedCases.length;
          payload.cases = selectedCases;
        }       
        this.createTestRun({ swal: this.$swal, handle: this.$route.params.handle, projectKey: this.$route.params.key, payload })
          .then(() => {
            this.UPDATE_SELECTED({});
            this.$router.push({
              name: this.$route.query?.page?this.$route.query?.page:'Runs',
              params: {
                handle: this.$route.params.handle,
                key: this.$route.params.key
              }
            }); 
          })
          .catch(error => {
            console.error("Failed to create Test Run:", error);
          });
      }
    },
    handleCases(selectedCases){
      this.selectedCases = selectedCases
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    toggleShowCases(){
      this.showSelectCases = !this.showSelectCases;
      if(!this.showSelectCases)
        this.selectedCases = []
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleDuplicateClick() {
      this.showConfirmBackDialog = false;
      this.$router.push({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          RunViewType: 'Duplicate', // TODO - don't do this via param
        },
      });
    },
    handleConfirmClick() {
      this.UPDATE_SELECTED({});
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.search_input {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 300px;
  }
}
.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
</style>
